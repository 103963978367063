<template>
  <div id="Map">
    <div id="map-container">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        container="map-parent"
        @load="onMapLoaded"
        :zoom="zoom"
        :center="center"
        :attributionControl="attributionControl"
      >
        <MglNavigationControl position="top-left" />
      </MglMap>
    </div>
    <div class="slider-wrapper-mobile" v-if="testMobile === true">
      <div class="container">
        <div class="row">
          <div class="col-12 m-2 d-flex flex-column">
            <h3>NYC - A Decade in Review</h3>
            <div class="mb-2">
              Built by <a href="https://ui.kpf.com/" target="_blank">KPFui</a>
            </div>
            <div style="font-size: 11px">
              Explore the interactive map by clicking on lots for
              additional&nbsp;information.
            </div>
            <small class=""
              >Analytic functionality of web app available on desktop or
              tablet.</small
            >
          </div>
        </div>
      </div>
    </div>
    <div class="slider-wrapper" v-if="testMobile === false">
      <div class="container">
        <div class="row">
          <div class="col-12 m-2 d-flex flex-column">
            <h1>NYC - A Decade in Review</h1>
            <small>v.{{ version }}</small>
            <div>
              Built by <a href="https://ui.kpf.com/" target="_blank">KPFui</a>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mt-3">
          <div class="col-12 m-2">
            <h3>Explore the Data</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-8 m-2 d-flex flex-column">
            <div
              class="total-lots d-flex flex-row align-items-center justify-content-between"
            >
              <h6>Total Lots</h6>
              <h5>{{ totalLotsFormat }}</h5>
            </div>
            <div
              class="total-lots d-flex flex-row align-items-center justify-content-between"
            >
              <h6>Gross Building Area</h6>
              <h5>{{ grossBuidingArea }} sqft</h5>
            </div>
            <div
              class="total-lots d-flex flex-row align-items-center justify-content-between"
            >
              <h6>Percent Building Area Selected</h6>
              <h5>{{ buildingAreaPercent }} %</h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col m-2">
            <h5>Building Area by Year</h5>
            <small>Drag mouse over graph to filter dataset</small>
          </div>
        </div>
        <div class="row mt-2">
          <div
            class="col-10 violin-plot-container mx-auto"
            ref="violinPlotContainer"
          >
            <svg id="violinPlotContainer" :height="height" :width="width"></svg>
          </div>
        </div>
        <div class="row">
          <div class="col-10 m-2 mx-auto">
            <button
              class="button-primary"
              @click="clearSelection($event)"
              stop-prop
            >
              Clear Selection
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-10 m-2 mx-auto">
            <h5>Clamp Building Area Domain</h5>
            <small
              >Range available between 0 and 8,837,500 million square
              feet</small
            >
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-10 mx-auto d-flex justify-content-between mt-2">
            <input
              type="number"
              name="violinLowerBound"
              id="violinLowerBound"
              v-model="violinSliderValues[0]"
              @change="incrementScale"
              value="balls"
              min="0"
            />
            <input
              type="number"
              name="violinUpperBound"
              id="violinUpperBound"
              v-model="violinSliderValues[1]"
              @change="incrementScale"
              max="8837500"
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-10 d-flex justify-content-start mx-auto mt-2 mb-2">
            <vue-slider
              class="w-100"
              @drag-end="incrementScale"
              v-model="violinSliderValues"
              v-bind="violinSliderOptions"
              :tooltip-formatter="formatSqft"
              ref="violin-slider"
            ></vue-slider>
          </div>
        </div>
        <hr />
        <div class="row mt-3">
          <div class="col-12 m-2">
            <h3>Borough Analysis</h3>
          </div>
        </div>
        <div class="row mt-2 ml-2">
          <div class="col-6 bar-plot-container" ref="barPlotContainer">
            <h6>Building Area by Borough</h6>
            <svg
              id="barPlotContainer"
              class="mt-4"
              :height="barHeight"
              :width="barWidth"
            ></svg>
          </div>
          <div class="col-6 bar-plot-container" ref="stackedPlotContainer">
            <h6>Land Use by Borough</h6>
            <svg
              id="stackedPlotContainer"
              class="mt-4"
              :height="barHeight"
              :width="barWidth"
            ></svg>
          </div>
        </div>
        <div class="row mt-5"></div>
        <div class="row mt-1">
          <div class="col-12 m-2">
            <h6>Land Use Legend</h6>
          </div>
        </div>
        <div
          class="row mt-2 ml-2"
          v-for="i in Math.ceil(landUseKeysObject.length / 2)"
          :key="i"
        >
          <div
            class="m-2 legend-column d-flex"
            v-for="item in landUseKeysObject.slice((i - 1) * 3, i * 3)"
            :key="item.name"
          >
            <div
              class="legend-square mr-1"
              :style="{ background: item.color }"
            ></div>
            {{ item.name }}
          </div>
        </div>
        <hr />
        <div class="row mt-3">
          <div class="col">
            Data
            <a
              href="https://www1.nyc.gov/site/planning/data-maps/open-data/dwn-pluto-mappluto.page"
              target="_blank"
              >NYC Pluto Data 2000 - 2019</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="legend-container frosted-glass" v-show="legendShow">
      <div class="exit-button" @click="legendShow = false">X</div>
      <div class="d-flex justify-content-between p-2 flex-column">
        <h4>Map Legend</h4>
        <small>Lots colored by total building area</small>
      </div>
      <canvas id="legendBox" width="300" :height="legendHeight"></canvas>
      <div class="d-flex justify-content-between mt-1">
        <small>{{ buildingAreaLowBound }} sqft</small
        ><small>{{ buildingAreaHighBound }} sqft</small>
      </div>
    </div>
    <div class="legend-container show d-flex" v-if="legendShow === false">
      <button class="button-primary bevel" @click="legendShow = true" stop-prop>
        Show Legend
      </button>
    </div>
    <div
      class="tutorial-container frosted-glass tutorial d-flex justify-content-center"
      v-if="tutorialShow === true && testMobile === false"
    >
      <div class="container">
        <div class="exit-button" @click="tutorialShow = false">X</div>
        <div class="row mt-1">
          <div class="col m-2">
            <h4>{{ tutorialHeader }}</h4>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col m-2">
            <h6 v-if="insightIndex < tutorialData.length - 1">
              {{ tutorialData[insightIndex].insight }}
            </h6>
            <h6 v-if="insightIndex === tutorialData.length - 1">
              {{ tutorialData[insightIndex].insight }}
              <a href="https://twitter.com/kpf_ui" target="_blank">@kpf_ui</a>
              on Twitter!
            </h6>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col m-2 d-flex flex-row justify-content-between">
            <div
              class="d-flex align-items-center tutorial-button"
              @click="incrementInsight('minus')"
            >
              <arrowIcon class="mr-2 arrowIcon left" v-if="insightIndex > 0" />
              {{ tutorialPrevious }}
            </div>
            <div
              class="d-flex align-items-center tutorial-button"
              @click="incrementInsight('add')"
            >
              {{ tutorialNext }}
              <arrowIcon
                class="ml-2 arrowIcon right"
                style="transform: rotate(180deg)"
                v-if="insightIndex < tutorialData.length - 1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tutorial-container tutorial show d-flex"
      v-if="tutorialShow === false && testMobile === false"
    >
      <button
        class="button-primary bevel"
        @click="tutorialShow = true"
        stop-prop
      >
        Resume Story
      </button>
    </div>
    <Loading :loadingFlag="loadingFlag" />
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from "mapbox-gl";
import { MglMap, MglNavigationControl } from "vue-mapbox";
// D3 FETCH FUNCTIONS
// CAN USE JSON OR CSV OR TSV ETC.,
import { json } from "d3-fetch";
import { extent, max, histogram, sum, quantile } from "d3-array";
import { select, selectAll, event, mouse } from "d3-selection";
import { brush } from "d3-brush";
import { scaleLinear, scaleBand, scaleOrdinal } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { transition, easeLinear } from "d3-transition";
import { curveCatmullRom, area, stack } from "d3-shape";
import { nest } from "d3-collection";
import { format } from "d3-format";
import { schemePuRd } from "d3-scale-chromatic";
// import chroma from "chroma-js";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

import Loading from "@/components/loading.vue";
import arrowIcon from "@/assets/arrow-left-solid.svg";
// global variables for ui use
// do not add to vue instance as with multiply memory use :)
var map = null;
var plutoData = [];
var dots = null;
var scatterSvg = null;
var barSvg = null;
var stackedSvg = null;
var violinSvg = null;
var valuesWithinBrush = [];
var millionFormat = format(".2s");
var commaFormat = format(",");
var brushContainer = brush();
// base data structure
var boroughs = {};
// SETUP LANDUSE SCALES
var augmentedColorScale = schemePuRd[9].filter(function (d, i) {
  return i !== 0;
});
var popup = null;

var landUseColorScale = scaleOrdinal().range(augmentedColorScale);

export default {
  name: "Map",
  data() {
    return {
      version: "1.0.0",
      // MAPBOX LIGHT STYLE TOKEN AND URL WITH BASIC SETTINGS
      accessToken:
        'pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA',
      mapStyle: 'mapbox://styles/kpfui/ckpee7ex8063d18pgand9peev',
      zoom: 11,
      attributionControl: false,
      // NY center
      center: [-73.96, 40.76],
      width: 500,
      height: 300,
      barHeight: 200,
      barWidth: 250,
      margin: {
        top: 20,
        right: 30,
        bottom: 30,
        left: 40,
      },
      legendHeight: 15,
      legendShow: true,
      xViolinScale: scaleBand().padding(0.5),
      yScale: scaleLinear(),
      colorScale: scaleLinear(),
      xBarScale: scaleBand(),
      yBarScale: scaleLinear(),
      yStackedScale: scaleLinear(),
      loadingFlag: true,
      defaultMapFillColor: [
        "interpolate",
        ["linear"],
        ["get", "BldgArea"],
        0,
        "#3AB2E7",
        50000,
        "#5B96DF",
        750000,
        "#945FC4",
        1000000,
        "#D914B8",
        40000000,
        "#FF01EF",
      ],
      mapColors: ["#3AB2E7", "#5B96DF", "#945FC4", "#C12DA6", "#F207A0"],
      violinSliderValues: [0, 8840000],
      violinSliderOptions: {
        dotSize: 14,
        min: 0,
        max: 8840000,
        lazy: true,
        contained: true,
        interval: 5000,
        enableCross: false,
        minRange: 1000,
        railStyle: {
          "background-color": "rgba(201,210,211,0.41)",
        },
        dotStyle: {
          "background-color": "#C9D2D3",
        },
        processStyle: {
          "background-color": "#945FC4",
        },
      },
      formatSqft: (value) => `${"" + millionFormat(value) + " sqft"}`,
      landUseDict: {
        "01": "One & Two Family Buildings",
        "02": "Multi-Family Buildings",
        // "03":"Multi-Family Elevator Buildings",
        "04": "Mixed Residential & Commercial Buildings",
        "05": "Commercial & Office Buildings",
        "06": "Public Facilities & Transporation Utility",
        // "07":"Transportation & Utility",
        "08": "Public Facilities & Institutions",
        "09": "Open Space & Outdoor Recreation",
        10: "Parking Facilities",
      },
      boroughNameDict: {
        BK: "Brooklyn",
        BX: "Bronx",
        MN: "Manhattan",
        QN: "Queens",
        SI: "Staten Island",
      },
      popupCreated: false,
      totalLots: 0,
      totalBuildingArea: 0,
      stackedLegendHeight: 200,
      stackedLegendWidth: 500,
      landUseKeysObject: [],
      totalNYCBuiltArea: 5868089080.744901,
      tutorialShow: true,
      tutorialData: [
        {
          insight:
            "5.2% of all building area in New York City was built in the last decade.",
          startPos: [2010, 8837500],
          endPos: [2019, 0],
          flyTo: false,
          sliderValues: [0, 8837500],
          zoom: 11,
          center: [-73.96, 40.76],
        },
        {
          insight:
            "This includes significant developments like Hudson Yards, Long Island City, and Downtown Brooklyn. In comparison, 6.3% of all built area in NYC was constructed from 2000 to 2009. Does this defy your expectations?",
          startPos: [2000, 8837500],
          endPos: [2009, 0],
          flyTo: false,
          sliderValues: [0, 8837500],
          zoom: 11,
          center: [-73.96, 40.76],
        },
        {
          insight:
            "One World Trade Center has by far the largest gross building area of any lot in the past 20 years — 8.8 million square feet. This is because the entire lot spans several streets and includes ten large buildings.",
          startPos: [2000, 8837500],
          endPos: [2019, 0],
          flyTo: true,
          OBJECTID: 107885,
          sliderValues: [0, 8837500],
          zoom: 15,
          coords: [-74.01306996972284, 40.71300382722421],
        },
        {
          insight:
            "The lot with the second largest gross building area is part of the Hudson Yards development on the west side of Manhattan. The towers and retail podium of 10 & 30 Hudson Yards, designed by KPF, were completed in 2015 with a gross building area of 3.3 million square feet.",
          startPos: [2015, 8837500],
          endPos: [2015, 0],
          flyTo: true,
          OBJECTID: 144577,
          sliderValues: [0, 8837500],
          zoom: 16,
          coords: [-74.00097157897805, 40.75435038802766],
        },
        {
          insight:
            "Prospect Park opened in 1867 and was renovated in 2013. The current PLUTO data registers the lot at 2013 with 38 buildings but zero gross floor area. Due to this data anomaly, the park visually represents a large building area in 2013 even with no new construction.",
          startPos: [2000, 500],
          endPos: [2019, -1],
          flyTo: true,
          OBJECTID: 253828,
          sliderValues: [0, 1000],
          zoom: 13,
          coords: [-73.96923682861184, 40.67235093227981],
        },
        {
          insight:
            "While there have been large structures built in the last 20 years, most (86%) were less than 10,000 square feet.",
          startPos: [2000, 10000],
          endPos: [2019, 0],
          flyTo: false,
          zoom: 11,
          center: [-73.96, 40.76],
          sliderValues: [0, 10000],
        },
        {
          insight:
            "Use the chart to the left to explore the data and share your insights with ",
          startPos: [2010, 8837500],
          endPos: [2019, 0],
          flyTo: false,
          zoom: 11,
          center: [-73.96, 40.76],
          sliderValues: [0, 8837500],
        },
      ],
      insightIndex: 0,
    };
  },
  components: {
    MglMap,
    MglNavigationControl,
    Loading,
    VueSlider,
    arrowIcon,
  },
  created() {
    // ATTACHES MAP TO INSTANCE
    this.mapbox = Mapbox;
  },
  computed: {
    buildingAreaLowBound() {
      return millionFormat(this.violinSliderValues[0]);
    },
    buildingAreaHighBound() {
      return millionFormat(this.violinSliderValues[1]);
    },
    buildingAreaPercent() {
      return (
        Math.round(
          (this.totalBuildingArea / this.totalNYCBuiltArea) * 100 * 100
        ) / 100
      );
    },
    totalLotsFormat() {
      return commaFormat(this.totalLots);
    },
    grossBuidingArea() {
      return millionFormat(this.totalBuildingArea);
    },
    tutorialNext() {
      return this.insightIndex < this.tutorialData.length - 1 ? "Next" : " ";
    },
    tutorialPrevious() {
      return this.insightIndex > 0 ? "Previous" : " ";
    },
    testMobile() {
      var width = window.innerWidth;
      var isMobile;
      if (width < 992) {
        isMobile = true;
      } else {
        isMobile = false;
      }
      return isMobile;
    },
    tutorialHeader() {
      return this.insightIndex === this.tutorialData.length - 1
        ? "Explore the Data"
        : "Did you Know";
    },
  },
  methods: {
    flyTo(coords, zoom) {
      map.flyTo({
        center: [coords[0], coords[1]],
        zoom: zoom,
        speed: 0.7,
        curve: 1,
        easing(t) {
          return t;
        },
      });
    },
    incrementInsight(type) {
      if (type === "minus" && this.insightIndex > 0) {
        this.insightIndex--;
      } else if (
        type === "add" &&
        this.insightIndex < this.tutorialData.length - 1
      ) {
        this.insightIndex++;
      }

      this.createTutorial(this.tutorialData[this.insightIndex]);
    },
    clearSelection(event) {
      event.stopPropagation();
      map.setLayoutProperty(
        "default-plutoData-polygons",
        "visibility",
        "visible"
      );
      this.updateBarGraph(plutoData["features"], "default");
      violinSvg.select(".brush").call(brushContainer.move, null);
    },
    /**
     * @input startPos [start x-coord, start y-coord]
     * @input endPOs [end x-coord, end y-coord]
     */
    createTutorial(insightObject) {
      this.violinSliderValues = insightObject.sliderValues;
      this.incrementScale("tutorial");

      const [startX, startY] = [
        this.xViolinScale(insightObject.startPos[0]),
        this.yScale(insightObject.startPos[1]),
      ];
      const [endX, endY] = [
        this.xViolinScale(insightObject.endPos[0]) +
          this.xViolinScale.bandwidth(),
        this.yScale(insightObject.endPos[1]),
      ];
      select(".brush")
        .transition()
        .delay(250)
        .duration(1000)
        .call(brushContainer.move, [
          [startX, startY],
          [endX, endY],
        ]);

      if (insightObject.flyTo === true) {
        plutoData["features"].forEach((d) => {
          if (d["properties"]["OBJECTID"] === insightObject["OBJECTID"]) {
            this.flyTo(insightObject.coords, insightObject.zoom);
            select(".mapboxgl-popup").remove();
            this.drawTooltip(d["properties"], insightObject.coords);
          }
        });
      } else if (insightObject.flyTo === false) {
        select(".mapboxgl-popup").remove();
        this.flyTo(insightObject.center, insightObject.zoom);
      }
    },
    createMapLegend() {
      var c = document.getElementById("legendBox");
      var ctx = c.getContext("2d");

      var grd = ctx.createLinearGradient(0, 0, 300, 0);

      grd.addColorStop(0, this.mapColors[0]);
      grd.addColorStop(0.2, this.mapColors[1]);
      grd.addColorStop(0.5, this.mapColors[2]);
      grd.addColorStop(0.7, this.mapColors[3]);
      grd.addColorStop(1, this.mapColors[4]);

      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, 300, this.legendHeight);
    },
    brushed(mouseType) {
      // empty array for return selection
      valuesWithinBrush = [];
      /**
       * checks for user selection
       */
      if (event.selection) {
        const [[x0, y0], [x1, y1]] = event.selection;
        /**
         * returns array
         * array is features within brush x,y bounds based on scale coordinate return
         */
        valuesWithinBrush = plutoData["features"].filter(
          (d) =>
            x0 <= this.xViolinScale(d["properties"]["year_built"]) &&
            this.xViolinScale(d["properties"]["year_built"]) < x1 &&
            y0 <= this.yScale(d["properties"]["BldgArea"]) &&
            this.yScale(d["properties"]["BldgArea"]) < y1
        );

        // check to see if default layer is on
        // if so change its visibility
        // if not turn it back on
        // this is to not load this layer more than once in DOM
        // If a layer with ID 'state-data' exists, remove it.

        if (valuesWithinBrush.length > 0 && mouseType === "end") {
          if (
            map.getLayer("plutoData-polygons") ||
            map.getSource("user-selected-plutoData-polygons")
          ) {
            map.removeLayer("plutoData-polygons");
            map.removeSource("user-selected-plutoData-polygons");
          }
          // turn default layer off
          map.setLayoutProperty(
            "default-plutoData-polygons",
            "visibility",
            "none"
          );

          var objectIDs = valuesWithinBrush.map(function (d) {
            boroughs[d["properties"]["Borough"]] += d["properties"]["BldgArea"];
            return d["properties"]["OBJECTID"];
          });
          // updates bar graph with brushed data
          this.updateBarGraph(valuesWithinBrush, "update");
          this.totalLots = valuesWithinBrush.length;

          var binArray = [];
          var mapIntervals = [];
          var mapColors = [];
          // gets extent of current brushed data set
          valuesWithinBrush.forEach((d) => {
            if (mapIntervals.includes(d["properties"]["BldgArea"]) === false) {
              mapIntervals.push(d["properties"]["BldgArea"]);
            }
          });

          mapIntervals.sort((a, b) => a - b);

          if (mapIntervals.length <= 1) {
            binArray = [0, mapIntervals[mapIntervals.length - 1]];
            mapColors = [
              this.mapColors[0],
              this.mapColors[this.mapColors.length - 1],
            ];
          } else {
            for (let i = 0; i < 1.25; i += 0.25) {
              binArray.push(quantile(mapIntervals, i));
            }
            mapColors = this.mapColors;
          }

          var mapColorScale = scaleLinear().domain(binArray).range(mapColors);

          /**
           * joins map colors to linear color scale relative to current user selection
           * mutates mapFillColor object with new interval for interpolation
           */

          function createMapColorScale(bins) {
            if (binArray.length > 0) {
              bins.forEach((bin, index) => {
                mapFillColor.push(bin, mapColorScale(bin));
                binArray.splice(index, 1);
                createMapColorScale(binArray);
              });
            }
          }

          let mapFillColor = ["interpolate", ["linear"], ["get", "BldgArea"]];
          createMapColorScale(binArray);

          // add custom source with filtered user selected lots
          map.addSource("user-selected-plutoData-polygons", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: valuesWithinBrush,
            },
          });

          // add custom layer with user selected results
          map.addLayer({
            id: "plutoData-polygons",
            type: "fill",
            source: "user-selected-plutoData-polygons",
            paint: {
              "fill-color": mapFillColor,
              "fill-opacity": 0.8,
            },
          });

          this.popupCreated = false;
          this.createMapTooltip("plutoData-polygons");
        }
      } else if (mouseType === "end") {
        selectAll(".scatter-circle").attr("opacity", 0.7);
        map.setLayoutProperty(
          "default-plutoData-polygons",
          "visibility",
          "visible"
        );
        this.popupCreated = false;
        this.totalLots = plutoData["features"].length;
        this.createMapTooltip("default-plutoData-polygons");
        this.updateBarGraph(plutoData["features"], "default");
      }
    },
    incrementScale(type) {
      // removes brush on plot
      if (type === undefined) {
        violinSvg.select(".brush").call(brushContainer.move, null);
      }

      this.yScale.domain(this.violinSliderValues);

      violinSvg
        .select(".violin-y-axis")
        .transition()
        .duration(1000)
        .call(axisLeft(this.yScale).ticks(10).tickFormat(millionFormat));

      var histogramBins = histogram()
        .domain(this.yScale.domain())
        .thresholds(this.yScale.ticks(10)) // Important: how many bins approx are going to be made? It is the 'resolution' of the violin plot
        .value((d) => d);

      // Compute the binning for each group of the dataset
      var sumstat = nest() // nest function allows to group the calculation per level of a factor
        .key(function (d) {
          return d["properties"]["year_built"];
        })
        .rollup(function (d) {
          // For each key..

          var input = d.map(function (g) {
            return g["properties"]["BldgArea"];
          }); // Keep the variable called Sepal_Length
          var bins = histogramBins(input); // And compute the binning on it.
          return bins;
        })
        .entries(plutoData["features"]);

      // What is the biggest number of value in a bin? We need it cause this value will have a width of 100% of the bandwidth.
      var maxNum = 0;
      for (var i in sumstat) {
        var allBins = sumstat[i].value;
        var lengths = allBins.map(function (a) {
          return a.length;
        });
        var longuest = max(lengths);
        if (longuest > maxNum) {
          maxNum = longuest;
        }
      }

      // The maximum width of a violin must be x.bandwidth = the width dedicated to a group
      var xNum = scaleLinear()
        .range([0, this.xViolinScale.bandwidth()])
        .domain([-maxNum, maxNum]);

      selectAll(".my-violin")
        .data(sumstat)
        .datum(function (d) {
          return d["value"];
        }) // So now we are working bin per bin
        .transition()
        .duration(1500)
        .attr(
          "d",
          area()
            .x0((d) => {
              return xNum(-d.length);
            })
            .x1((d) => {
              return xNum(d.length);
            })
            .y((d) => {
              return this.yScale(d.x0);
            })
            .curve(curveCatmullRom)
        );
    },
    createViolinPlot(plutoData) {
      violinSvg = select("#violinPlotContainer").attr("viewBox", [
        0,
        0,
        this.width,
        this.height,
      ]);

      let xScaleYearArray = [];
      for (var i = 2000; i <= 2019; i++) {
        xScaleYearArray.push(i);
      }

      this.xViolinScale.domain(xScaleYearArray).range([0, this.width]);

      this.yScale.range([this.height - this.margin.bottom, this.margin.top]);

      this.bldgAreaExtent = extent(plutoData, (d) => {
        return d["properties"]["BldgArea"];
      });
      this.defaultExtent = this.bldgAreaExtent;
      this.yScale.domain(this.bldgAreaExtent);

      violinSvg
        .append("g")
        .attr("class", "axis violin-y-axis")
        .call(axisLeft(this.yScale).tickFormat(millionFormat))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -15 - this.margin.left)
        .attr("x", 0 - this.height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .text("Building Area");

      violinSvg
        .append("g")
        .attr("class", "axis violin-x-axis")
        .attr("transform", `translate(0,${this.height - this.margin.bottom})`)
        .call(
          axisBottom(this.xViolinScale).tickFormat((d) => {
            return d % 2 === 0 ? d : " ";
          })
        )
        .append("text")
        .attr("x", this.width - this.margin.right)
        .attr("y", this.margin.bottom)
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .attr("text-anchor", "end")
        .text("Year Built");

      var histogramBins = histogram()
        .domain(this.yScale.domain())
        .thresholds(this.yScale.ticks(5)) // Important: how many bins approx are going to be made? It is the 'resolution' of the violin plot
        .value((d) => d);

      // Compute the binning for each group of the dataset
      var sumstat = nest() // nest function allows to group the calculation per level of a factor
        .key(function (d) {
          return d["properties"]["year_built"];
        })
        .rollup(function (d) {
          // For each key..

          var input = d.map(function (g) {
            return g["properties"]["BldgArea"];
          });
          var bins = histogramBins(input); // And compute the binning on it.
          return bins;
        })
        .entries(plutoData);

      // What is the biggest number of value in a bin? We need it cause this value will have a width of 100% of the bandwidth.
      var maxNum = 0;
      for (i in sumstat) {
        var allBins = sumstat[i].value;
        var lengths = allBins.map(function (a) {
          return a.length;
        });
        var longuest = max(lengths);
        if (longuest > maxNum) {
          maxNum = longuest;
        }
      }

      // The maximum width of a violin must be x.bandwidth = the width dedicated to a group
      var xNum = scaleLinear()
        .range([0, this.xViolinScale.bandwidth()])
        .domain([-maxNum, maxNum]);

      violinSvg
        .selectAll("myViolin")
        .data(sumstat)
        .enter() // So now we are working group per group
        .append("g")
        .attr("transform", (d) => {
          return "translate(" + this.xViolinScale(d["key"]) + " ,0)";
        }) // Translation on the right to be at the group position
        .append("path")
        .datum(function (d) {
          return d["value"];
        }) // So now we are working bin per bin
        .attr("class", "my-violin")
        .style("stroke", "none")
        .style("fill", "#945FC4")
        .attr(
          "d",
          area()
            .x0((d) => {
              return xNum(-d.length);
            })
            .x1((d) => {
              return xNum(d.length);
            })
            .y((d) => {
              return this.yScale(d.x0);
            })
            .curve(curveCatmullRom)
        );

      brushContainer.on("start", this.brushed);
      brushContainer.on("end", (i) => {
        this.brushed("end");
      });

      violinSvg.append("g").attr("class", "brush").call(brushContainer);

      this.createBarGraph();
      this.createTutorial(this.tutorialData[this.insightIndex]);
    },
    shapeStackedGraphData(boroughArray) {
      var stackedArray = [];
      var bldgAreaStackedArray = [];

      // shape dataset for stacked analysis
      boroughArray.forEach((d) => {
        var values = Object.values(d["landUse"]);
        var bldgValues = Object.values(d["landUseBldg"]);

        //bldg area stacked bar chart
        d["landUseBldg"]["total"] = sum(bldgValues);
        d["landUseBldg"]["name"] = d["name"];
        bldgAreaStackedArray.push(d["landUseBldg"]);
        // stacked bar chart
        d["landUse"]["total"] = sum(values);
        d["landUse"]["name"] = d["name"];
        stackedArray.push(d["landUse"]);
      });

      return [stackedArray, bldgAreaStackedArray];
    },
    updateBarGraph(filteredPlutoData, type) {
      this.resetBoroughObject();
      const boroughArray = this.shapeBoroughData(filteredPlutoData);
      var [stackedArray, bldgAreaStackedArray] = this.shapeStackedGraphData(
        boroughArray
      );
      var landUseKeys = Object.keys(this.landUseDict);
      var yStackedExtent = max(
        stackedArray.map((d) => {
          return d["total"];
        })
      );

      this.yBarScale.domain([
        0,
        max(
          bldgAreaStackedArray.map((d) => {
            return d["total"];
          })
        ),
      ]);
      this.yStackedScale.domain([0, yStackedExtent]);

      barSvg
        .select(".yBarScale")
        .transition()
        .duration(1000)
        .call(axisLeft(this.yBarScale).tickFormat(millionFormat));

      stackedSvg
        .select(".yStackedScale")
        .transition()
        .duration(1000)
        .call(axisLeft(this.yStackedScale).ticks(5));

      barSvg
        .selectAll(".stacked-bar")
        .transition()
        .duration(300)
        .style("opacity", 0)
        .remove();

      stackedSvg
        .selectAll(".stacked-bar")
        .transition()
        .duration(300)
        .style("opacity", 0)
        .remove();

      stackedSvg.selectAll(".stacked-group").remove();
      barSvg.selectAll(".stacked-group").remove();

      barSvg
        .selectAll(".stacked-group")
        .data(stack().keys(landUseKeys)(bldgAreaStackedArray))
        .enter()
        .append("g")
        .attr("class", "stacked-group")
        .attr("fill", function (d) {
          return landUseColorScale(d.key);
        })
        .selectAll(".stacked-bar")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("rect")
        .attr("class", "stacked-bar")
        .attr("opacity", 0)
        .attr("y", this.yBarScale(0))
        .attr("x", (d) => {
          return this.xBarScale(d.data.name);
        })
        .attr("width", this.xBarScale.bandwidth())
        .transition()
        .duration(1000)
        .attr("opacity", 1)
        .attr("y", (d) => {
          return this.yBarScale(d[1]);
        })
        .attr("height", (d) => {
          return this.yBarScale(d[0]) - this.yBarScale(d[1]);
        });

      stackedSvg
        .selectAll(".stacked-group")
        .data(stack().keys(landUseKeys)(stackedArray))
        .enter()
        .append("g")
        .attr("class", "stacked-group")
        .attr("fill", function (d) {
          return landUseColorScale(d.key);
        })
        .selectAll(".stacked-bar")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("rect")
        .attr("class", "stacked-bar")
        .attr("opacity", 0)
        .attr("y", this.yStackedScale(0))
        .attr("x", (d) => {
          return this.xBarScale(d.data.name);
        })
        .attr("width", this.xBarScale.bandwidth())
        .transition()
        .duration(1000)
        .attr("opacity", 1)
        .attr("y", (d) => {
          return this.yStackedScale(d[1]);
        })
        .attr("height", (d) => {
          return this.yStackedScale(d[0]) - this.yStackedScale(d[1]);
        });

      selectAll(".label").remove();

      stackedSvg
        .selectAll(".label")
        .data(stackedArray)
        .enter()
        .append("text")
        .transition()
        .duration(500)
        .style("opacity", 0)
        .attr("class", "label")
        .attr("x", (d) => {
          return this.xBarScale.bandwidth() / 2 + this.xBarScale(d["name"]);
        })
        .attr("y", (d) => {
          return this.yStackedScale(d["total"]) - 20;
        })
        .attr("dy", ".75em")
        .transition()
        .duration(500)
        .style("opacity", 1)
        .text(function (d) {
          if (d["total"] === 0) {
            return " ";
          } else {
            return millionFormat(d["total"]);
          }
        });

      barSvg
        .selectAll(".label")
        .data(bldgAreaStackedArray)
        .enter()
        .append("text")
        .transition()
        .duration(500)
        .style("opacity", 0)
        .attr("class", "label")
        .attr("x", (d) => {
          return this.xBarScale.bandwidth() / 2 + this.xBarScale(d["name"]);
        })
        .attr("y", (d) => {
          return this.yBarScale(d["total"]) - 20;
        })
        .attr("dy", ".75em")
        .transition()
        .duration(500)
        .style("opacity", 1)
        .text(function (d) {
          if (d["total"] === 0) {
            return " ";
          } else {
            return millionFormat(d["total"]);
          }
        });
    },
    resetBoroughObject() {
      var landUseKeys = Object.keys(this.landUseDict);
      landUseKeys.push("total");

      boroughs = {
        BX: { name: "Bronx", BldgArea: 0 },
        BK: { name: "Brooklyn", BldgArea: 0 },
        MN: { name: "Manhattan", BldgArea: 0 },
        QN: { name: "Queens", BldgArea: 0 },
        SI: { name: "Staten Island", BldgArea: 0 },
      };

      var boroughKeys = Object.keys(boroughs);
      boroughKeys.forEach(function (key) {
        boroughs[key]["LandUse"] = {};
        boroughs[key]["landUseBldg"] = {};
        landUseKeys.forEach((d) => {
          boroughs[key]["LandUse"][d] = 0;
          boroughs[key]["landUseBldg"][d] = 0;
        });
      });
    },
    shapeBoroughData(data) {
      this.totalBuildingArea = 0;
      this.totalLots = data.length;
      // creata borough data object for building area and landUse keys
      data.forEach((d) => {
        var borough = d["properties"]["Borough"];
        boroughs[borough]["BldgArea"] += d["properties"]["BldgArea"];
        this.totalBuildingArea += d["properties"]["BldgArea"];
        if (
          Object.keys(boroughs[borough]["LandUse"]).includes(
            d["properties"]["LandUse"]
          ) === true
        ) {
          /**
           *  combining landuse fields
           *  06 represents 06 and 07
           *  02 represents 02 and 03
           */
          if (
            d["properties"]["LandUse"] === "07" ||
            d["properties"]["LandUse"] === null
          ) {
            boroughs[borough]["LandUse"]["06"] += 1;
            boroughs[borough]["landUseBldg"]["06"] +=
              d["properties"]["BldgArea"];
          }
          if (d["properties"]["LandUse"] === "03") {
            boroughs[borough]["LandUse"]["02"] += 1;
            boroughs[borough]["landUseBldg"]["02"] +=
              d["properties"]["BldgArea"];
          } else {
            boroughs[borough]["LandUse"][d["properties"]["LandUse"]] += 1;
            boroughs[borough]["landUseBldg"][d["properties"]["LandUse"]] +=
              d["properties"]["BldgArea"];
          }
        }
      });
      return Object.keys(boroughs).map(function (key) {
        return {
          name: boroughs[key]["name"],
          landUse: boroughs[key]["LandUse"],
          landUseBldg: boroughs[key]["landUseBldg"],
        };
      });
    },
    createBarGraph() {
      // resets/ creates bar graph data
      this.resetBoroughObject();
      // shapes object into iterable array
      const boroughArray = this.shapeBoroughData(plutoData["features"]);
      // create features for landuse
      var [stackedArray, bldgAreaStackedArray] = this.shapeStackedGraphData(
        boroughArray
      );
      var landUseKeys = Object.keys(this.landUseDict);
      var boroughNames = boroughArray.map((d) => {
        return d["name"];
      });
      // creates object for legend
      this.landUseKeysObject = landUseKeys.map((d) => {
        return { name: this.landUseDict[d], color: landUseColorScale(d) };
      });

      // select SVG container for graphs append
      barSvg = select("#barPlotContainer").attr("viewBox", [
        0,
        0,
        this.barWidth,
        this.barHeight,
      ]);

      stackedSvg = select("#stackedPlotContainer").attr("viewBox", [
        0,
        0,
        this.barWidth,
        this.barHeight,
      ]);

      landUseColorScale.domain(landUseKeys);
      /**
       * create scales
       */
      this.xBarScale
        .domain(boroughNames)
        .rangeRound([this.margin.left, this.barWidth - this.margin.right])
        .padding(0.05);

      this.yBarScale
        .domain([
          0,
          max(
            bldgAreaStackedArray.map((d) => {
              return d["total"];
            })
          ),
        ])
        .rangeRound([this.barHeight - this.margin.bottom, this.margin.top]);
      this.yStackedScale
        .domain([
          0,
          max(
            stackedArray.map((d) => {
              return d["total"];
            })
          ),
        ])
        .rangeRound([this.barHeight - this.margin.bottom, this.margin.top]);

      barSvg
        .selectAll("g")
        .data(stack().keys(landUseKeys)(bldgAreaStackedArray))
        .enter()
        .append("g")
        .attr("class", "stacked-group")
        .attr("fill", function (d) {
          return landUseColorScale(d.key);
        })
        .selectAll("rect")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("rect")
        .attr("class", "stacked-bar")
        .attr("x", (d) => {
          return this.xBarScale(d.data.name);
        })
        .attr("y", (d) => {
          return this.yBarScale(d[1]);
        })
        .attr("height", (d) => {
          return this.yBarScale(d[0]) - this.yBarScale(d[1]);
        })
        .attr("width", this.xBarScale.bandwidth());

      barSvg
        .selectAll(".label")
        .data(bldgAreaStackedArray)
        .enter()
        .append("text")
        .attr("class", "label")
        .attr("x", (d) => {
          return this.xBarScale.bandwidth() / 2 + this.xBarScale(d["name"]);
        })
        .attr("y", (d) => {
          return this.yBarScale(d["total"]) - 20;
        })
        .attr("dy", ".75em")
        .text((d) => {
          return d["total"] === 0 ? " " : millionFormat(d["total"]);
        });

      var yBarAxis = barSvg
        .append("g")
        .attr("class", "axis yBarScale")
        .attr("transform", `translate(${this.margin.left},0)`)
        .call(axisLeft(this.yBarScale).ticks(5).tickFormat(millionFormat))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -15 - this.margin.left)
        .attr("x", 0 - this.barHeight / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .text("Total Building Area");

      var xBarAxis = barSvg
        .append("g")
        .attr("class", "axis xBarScale")
        .attr(
          "transform",
          `translate(0,${this.barHeight - this.margin.bottom})`
        )
        .call(axisBottom(this.xBarScale))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)")
        .append("text")
        .attr("x", this.barWidth - this.margin.right)
        .attr("y", this.margin.bottom)
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .attr("text-anchor", "end")
        .text("Borough");

      // var tooltip = select("body")
      //   .append("div")
      //   .attr("class", 'stacked-tooltip')
      //   .style("position", "absolute")
      //   .style("z-index", "10")
      //   .style("visibility", "hidden")

      stackedSvg
        .selectAll("g")
        .data(stack().keys(landUseKeys)(stackedArray))
        .enter()
        .append("g")
        .attr("class", "stacked-group")
        .attr("fill", function (d) {
          return landUseColorScale(d.key);
        })
        .selectAll("rect")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("rect")
        .attr("class", "stacked-bar")
        .attr("x", (d) => {
          return this.xBarScale(d.data.name);
        })
        .attr("y", (d) => {
          return this.yStackedScale(d[1]);
        })
        .attr("height", (d) => {
          return this.yStackedScale(d[0]) - this.yStackedScale(d[1]);
        })
        .attr("width", this.xBarScale.bandwidth());
      // .on("mouseover", function(){return tooltip.style("visibility", "visible");})
      // .on("mousemove", function(d){
      //   var tooltipInfo = "# of lots: " + (d[1]-d[0]).toString()
      //   tooltip.text(tooltipInfo)
      //   return tooltip.style("top", (event.pageY-10)+"px").style("left",(event.pageX+10)+"px")
      // })
      // .on("mouseout", function(){return tooltip.style("visibility", "hidden");});

      var yStackedAxis = stackedSvg
        .append("g")
        .attr("class", "axis yStackedScale")
        .attr("transform", `translate(${this.margin.left},0)`)
        .call(axisLeft(this.yStackedScale).ticks(5))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -15 - this.margin.left)
        .attr("x", 0 - this.barHeight / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("fill", "#000")
        .attr("font-weight", "bold")
        .text("Total Number of Lots");

      var xStackedAxis = stackedSvg
        .append("g")
        .attr("class", "axis xStackedScale")
        .attr(
          "transform",
          `translate(0,${this.barHeight - this.margin.bottom})`
        )
        .call(axisBottom(this.xBarScale))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)")
        .append("text")
        .attr("x", this.barWidth - this.margin.right)
        .attr("y", this.margin.bottom)
        .attr("text-anchor", "end")
        .text("Borough");

      stackedSvg
        .selectAll(".label")
        .data(stackedArray)
        .enter()
        .append("text")
        .attr("class", "label")
        .attr("x", (d) => {
          return this.xBarScale.bandwidth() / 2 + this.xBarScale(d["name"]);
        })
        .attr("y", (d) => {
          return this.yStackedScale(d["total"]) - 20;
        })
        .attr("dy", ".75em")
        .text((d) => {
          return millionFormat(d["total"]);
        });
    },
    onRender(e) {
      if (e.target && e.target.loaded()) {
        this.loadingFlag = !this.loadingFlag;
        map.off("render", this.onRender);
      }
    },
    drawTooltip(prop, geometry) {
      popup
        .setLngLat([geometry[0], geometry[1]])
        .setHTML(
          "<div class='container' style='pointer-events:none;'><div class='row'><div class='col flex-column'><h4>" +
            this.boroughNameDict[prop["Borough"]] +
            "</h4>Built in " +
            prop["year_built"] +
            "</div></div><hr /><div class='row mt-2'><div class='col tooltip-column'><h6>Land Use: </h6><h5> " +
            this.landUseDict[prop["LandUse"]] +
            "</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Built Floor to Area Ratio: </h6><h5> " +
            prop["BuiltFAR"] +
            "</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Lot Area: </h6><h5>" +
            millionFormat(prop["LotArea"]) +
            " sqft" +
            "</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Gross Building Area: </h6><h5>" +
            millionFormat(prop["BldgArea"]) +
            " sqft" +
            "</h5></div></div></div>"
        )
        .addTo(map);
    },
    createMapTooltip(mapLayer) {
      popup = new this.mapbox.Popup({ closeButton: true, closeOnClick: true });

      map.on("click", mapLayer, (e) => {
        e.originalEvent.cancelBubble = true;

        if (document.querySelector(".mapboxgl-popup") === null) {
          var prop = e.features[0]["properties"];

          popup
            .setLngLat([e["lngLat"]["lng"], e["lngLat"]["lat"]])
            .setHTML(
              "<div class='container' style='pointer-events:none;'><div class='row'><div class='col flex-column'><h4>" +
                this.boroughNameDict[prop["Borough"]] +
                "</h4>Built in " +
                prop["year_built"] +
                "</div></div><hr /><div class='row mt-2'><div class='col tooltip-column'><h6>Land Use: </h6><h5> " +
                this.landUseDict[prop["LandUse"]] +
                "</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Built Floor to Area Ratio: </h6><h5> " +
                prop["BuiltFAR"] +
                "</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Lot Area: </h6><h5>" +
                millionFormat(prop["LotArea"]) +
                " sqft" +
                "</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Gross Building Area: </h6><h5>" +
                millionFormat(prop["BldgArea"]) +
                " sqft" +
                "</h5></div></div></div>"
            )
            .addTo(map);
        }
      });

      map.on("mouseenter", mapLayer, (e) => {
        map.getCanvas().style.cursor = "pointer";
      });

      map.on("mouseleave", mapLayer, (e) => {
        map.getCanvas().style.cursor = "";
      });
    },
    // FIRES ON MAP LOAD
    onMapLoaded(event) {
      map = event.map;

      json("./pluto_data.json", (data, err) => {}).then((response) => {
        plutoData = response;
        // load all deafult lots
        // default is all features 2000-2019
        var visibilityTest = this.testMobile === false ? "none" : "visible";

        map.addLayer({
          id: "default-plutoData-polygons",
          type: "fill",
          source: {
            type: "geojson",
            data: plutoData,
          },
          layout: {
            visibility: visibilityTest,
          },
          paint: {
            "fill-color": this.defaultMapFillColor,
            "fill-opacity": 0.8,
          },
        });

        this.createMapTooltip("default-plutoData-polygons");
        this.createViolinPlot(plutoData["features"]);
        this.createMapLegend();

        // loading render flag event
        map.on("render", this.onRender);
      });
    },
  },
};
</script>

<style lang="scss">
$white: #fff;
$off-white: #ebebf0;
$highlight-color: #3ab2e7;
$black: #333;
$off-black: #1a1a1a;
$frosted-color: rgba(26, 26, 26, 0.45);
$grey: #c9d2d3;
$dark-grey: #383838;

$slider-width: 600px;

html,
body {
  height: 100%;
}

html {
  font-size: 12px; // Magic:
}
hr {
  border-color: rgba(235, 235, 230, 0.4) !important;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 14px;
  }
}

.legend-container {
  position: absolute;
  right: 15px;
  bottom: 15px;

  padding: 1rem;

  border-radius: 5px;

  border: 1px solid $dark-grey;

  pointer-events: none;

  &.show {
    border: none;
    background: transparent;
    pointer-events: all;
    z-index: 100;
  }
}
#legendBox {
  border-radius: 5px;
}
.tooltip-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h6 {
    color: rgba(235, 235, 230, 0.8);
  }
}
.mapboxgl-popup {
  color: $off-white;
}
.mapboxgl-popup-content {
  background: $frosted-color;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);

  border: 1px solid $dark-grey;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: $off-black;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: $off-black;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: $off-black;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: $off-black;
}
.mapboxgl-popup-close-button {
  color: $white;
  &:hover {
    color: $highlight-color;
  }
}
.mapboxgl-popup-close-button {
  font-size: 20px;
}
input {
  width: 150px;

  background: $dark-grey;

  color: $off-white;

  border: none;

  text-align: center;
}
.button-primary {
  border-radius: none;

  background: transparent !important;

  color: $white;

  border: 1px solid $dark-grey;

  &.bevel {
    border-radius: 5px;
    padding: 1rem;
    background: $off-black !important;
  }
  &:hover {
    border: 1px solid $off-white;
  }
}
.axis {
  font: 10px sans-serif;
}
.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.selection {
  fill: $grey !important;
  stroke: $black;
}
.handle--nw,
.handle--ne,
.handle--sw,
.handle--se {
  // fill: $black;
  fill: $off-white;
}
.label {
  font-size: 0.7rem;
  text-anchor: middle;

  fill: $off-white;
}
.exit-button {
  position: absolute;
  right: 15px;
  transition: color 0.1s ease;

  pointer-events: all;
  z-index: 100;

  &:hover {
    cursor: pointer;
    color: $highlight-color;
  }
}
.show-button {
  &:hover {
    cursor: pointer;
    color: $highlight-color;
  }
}
svg {
  overflow: visible !important;
}
a {
  outline: none;
  text-decoration: underline !important;

  &:hover {
    color: $highlight-color !important;
  }
}
#Map {
  width: 100vw;
  height: 100vh;
}
.mapboxgl-canvas {
  left: 0;
}
#map-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

  overflow: hidden;
}

.slider-wrapper-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;

  font-size: 1rem;
  background: $frosted-color;

  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);

  overflow: hidden;

  z-index: 100;
}
.slider-wrapper {
  position: absolute;
  max-height: 100vh;
  min-height: 100vh;
  width: $slider-width;

  background: $frosted-color;

  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 0 5rem 0;

  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
.vue-slider-mark {
  background-color: lighten($grey, 10%);
  border-radius: 50%;
  opacity: 0.5;
  line-height: 1.33;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
  color: $white;
}
.vue-slider-dot-handle {
  border: none !important;
}
text {
  fill: $off-white;
}
.axis {
  .domain {
    fill: none;
    stroke: $off-white;
  }

  .tick > line {
    stroke: $off-white;
  }

  text {
    fill: $off-white;
    font-weight: bold;
  }
}
.legend-column {
  font-size: 9px;
}
.legend-square {
  width: 15px;
  height: 15px;
}
.mapboxgl-ctrl-bottom-left {
  z-index: -1;
}
.stacked-tooltip {
  color: $white;
  background: $off-black;
  fill-opacity: 0.45;
  padding: 1rem;
  border-radius: 5px;
}
.frosted-glass {
  background: $frosted-color;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
.tutorial-container {
  position: absolute;
  right: 15px;
  max-width: 400px;

  padding: 1rem;

  // background: $off-black;

  border-radius: 5px;

  border: 1px solid $dark-grey;

  z-index: 2;

  &.legend {
    bottom: 15px;
  }
  &.tutorial {
    top: 15px;
  }
  &.show {
    background: transparent;
    pointer-events: all;
    border: none;
    z-index: 100;
  }
}
.tutorial-button {
  transition: all 0.1s ease-in;

  &:hover {
    color: $highlight-color;
    cursor: pointer;
    path {
      fill: $highlight-color;
    }
  }
}
.arrowIcon {
  height: 15px;
}

.mapboxgl-ctrl-top-left {
  left: calc(#{$slider-width + 5px});
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>