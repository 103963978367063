<template>
  <div class="loading-container" v-if="loadingFlag  === true">
    <div class="loading-counter">Loading</div>
    <div class="boxes">
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"loading",
  props:{
    loadingFlag: Boolean,
    // percentComplete: Number
  }
}
</script>

<style lang="scss" scoped>
$black:rgb(24,24,24);
$highlight-color: #3ad;
$white: #fff;

// $light-face: #000;
// $dark-face: rgb(33,33,33);
// $darkest-face: rgb(25,25,25);
// $frosted-color: rgba(245, 245, 245, 0.45);
// $text-color: $black;

$light-face: #fff;
$dark-face: rgb(250,250,250);
$darkest-face: rgb(240,240,240);
$frosted-color:rgba(33,33,33,0.45);
$text-color: $white;
// loading animation
.loading-container{
  position: absolute;
  display: flex;
  top: 0;
  left:0;
  height:100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: $text-color;
  pointer-events: none;
  cursor: not-allowed;
  // background-color: $black;

  // frosted glass effect
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: $frosted-color;

  z-index: 9999;

  .loading-counter{
    position: absolute;
    margin-top: 100px;
  }
}
.boxes {
    // height: 33vh;
    // width: 33vw;
    display: flex;
    // position: relative;
    // -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    // -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    // margin-top: 32px;
    // -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    // margin: 2rem;
}
.boxes .box {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0;
    position: absolute;
    // -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.boxes .box:nth-child(1) {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -webkit-animation: box1 1s linear infinite;
    animation: box1 1s linear infinite;
}
.boxes .box:nth-child(2) {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    -webkit-animation: box2 1s linear infinite;
    animation: box2 1s linear infinite;
}
.boxes .box:nth-child(3) {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    -webkit-animation: box3 1s linear infinite;
    animation: box3 1s linear infinite;
}
.boxes .box:nth-child(4) {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
    -webkit-animation: box4 1s linear infinite;
    animation: box4 1s linear infinite;
}


.boxes .box > div {
    // background: $light-face;
    background: transparent;
    border: 1px solid $light-face;
    --translateZ: 15.5px;
    --rotateY: 0deg;
    --rotateX: 0deg;
    position: absolute;
    width: 100%;
    height: 100%;
    // background: $light-face;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
    transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
}
.boxes .box > div:nth-child(1) {
    top: 0;
    left: 0;
    // background: $light-face;
    background: transparent;
    border: 1px solid $light-face
}
.boxes .box > div:nth-child(2) {
    // background: $darkest-face;
    background: transparent;
    border: 1px solid $darkest-face;
    right: 0;
    --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
    // background: $dark-face;
    border: 1px solid $dark-face;
    --rotateX: -90deg;
}

@keyframes box1 {
    0%,
    50% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(200%, 0);
    }
}
@keyframes box2 {
    0%{
        transform: translate(0, 100%);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}
@keyframes box3 {
    0%,
    50% {
        transform: translate(100%, 100%);
    }
    100% {
        transform: translate(0, 100%);
    }
}
@keyframes box4 {
    0%{
        transform: translate(200%, 0);
    }
    50% {
        transform: translate(200%, 100%);
    }
    100% {
        transform: translate(100%, 100%);
    }
} 
</style>