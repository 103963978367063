import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    plutoData: null
  },
  mutations: {
    getPlutoData(state){
      return state.plutoData
    },
    setPlutoData(state, newPlutoData){
      state.plutoData = newPlutoData
    }
  },
  actions: {
    readData: function(context){
      json("./pluto_filters_year_built_greater_equal_2000.json", function(data,err){})
        .then(function(response){
          plutoData = response
          context.commit("setPlutoData", plutoData)
        })
    }
  },
  modules: {
  }
})
